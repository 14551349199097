.sloading {
  padding: 10px 0;
  text-align: center;
  transition: opacity 0.6s ease-in-out;
}
.sloading > div {
  animation: wave 2s infinite ease-in-out;
  background: #000;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 0 3px;
  opacity: 0.25;
  transform: translateY(0);
  width: 10px;
}
.sloading > div:nth-child(1) {
  animation-delay: 0s;
}
.sloading > div:nth-child(2) {
  animation-delay: 0.1s;
}
.sloading > div:nth-child(3) {
  animation-delay: 0.2s;
}
.sloading > div:nth-child(4) {
  animation-delay: 0.3s;
}
.sloading > div:nth-child(5) {
  animation-delay: 0.4s;
}
@keyframes wave {
  0%,
  60%,
  100% {
    opacity: 0.25;
    transform: translateY(0);
  }
  20% {
    opacity: 0.75;
    transform: translateY(13px);
  }
  40% {
    opacity: 0.75;
    transform: translateY(-13px);
  }
}

.fullPage {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
